var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isTableLoading,
      expression: "isTableLoading"
    }],
    attrs: {
      "data": _vm.list
    }
  }, _vm._l(_vm.columns, function (column, index) {
    var _column$width;
    return _c('el-table-column', {
      key: index,
      attrs: {
        "width": (_column$width = column.width) !== null && _column$width !== void 0 ? _column$width : '',
        "sortable": _vm.columns[index].sortable,
        "label": _vm.columns[index] ? _vm.columns[index].label : column,
        "prop": index,
        "formatter": typeof _vm.columns[index].formatter === 'function' ? _vm.columns[index].formatter : null
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }